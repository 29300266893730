<template>
    <div>
        <div class="box">
            <el-page-header @back="$router.go(-1)" :content="$t('tExamGroupDetail')"></el-page-header>
        </div>
        <el-card class="content mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="ruleFrom row">
                <el-form-item :label="$t('exam').user_username" prop="user_username" class="col-4 mb0">
                    <div>{{ruleForm.user.username}}</div>
                </el-form-item>
                <el-form-item :label="$t('exam.group_name')" prop="group_name" class="col-4 mb0">
                    <div>{{ruleForm.group.name}}</div>
                </el-form-item>
                <el-form-item :label="$t('exam').is_bool" prop="is_bool" class="col-4 mb0">
                    <div>{{is_bool(ruleForm.is_bool)}}</div>
                </el-form-item>
                <el-form-item :label="$t('exam').answer" prop="answer" class="col-12 mb0">
                    <div>{{ruleForm.answer}}</div>
                </el-form-item>
                <el-form-item :label="$t('exam').boolean" prop="boolean" class="col-12 mb0">
                    <div>{{is_bool(ruleForm.boolean)}}</div>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="content mt20">
            <el-form label-position="left" :model="ruleForm" ref="ruleForm" label-width="100px" class="row">
                <el-form-item :label="$t('exam.exam_title')" prop="exam_title" class="col-12 mb0">
                    <div>{{ruleForm.exam.title}}</div>
                </el-form-item>
                <el-form-item :label="$t('exam.exam_content')" prop="exam_content" class="col-12 mb0">
                    <div v-html="ruleForm.exam.content"></div>
                </el-form-item>
                <el-form-item :label="$t('exam.exam_explain')" prop="exam_explain" class="col-12 mb0">
                    <div v-html="ruleForm.exam.explain"></div>
                </el-form-item>
                <el-form-item :label="$t('exam.exam_answer')" prop="exam_answer" class="col-12 mb0">
                    <div>{{ruleForm.exam.answer}}</div>
                </el-form-item>
                <el-form-item :label="$t('exam.exam_bool')" prop="exam_bool" class="col-12 mb0">
                    <div>{{is_bool(ruleForm.exam.bool)}}</div>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import { groupExamDetail } from '@/api/index'
export default {
    name:'groupExamDetail',
    data(){
        return {
            ruleForm:{
                id:'',
                user:{
                    id:'',
                    username:''
                },
                exam:{
                    id:'',
                    title:'',
                    content:'',
                    explain:'',
                    answer:'',
                    bool:0
                },
                answer:'',
                boolean:0,
                position:{
                    id:'',
                    title:''
                },
                subject:{
                    id:'',
                    title:''
                },
                chapter:{
                    id:'',
                    title:''
                },
                is_bool:0
            }
        }
    },
    computed:{
        id(){
			return Number.isNaN(Number(this.$route.query.id)) ? undefined : Number(this.$route.query.id)
        },
    },
    methods:{
        is_bool(is_bool){
            switch(is_bool){
                case 1:
                    return this.$t('exam.is_bool1')
                case 0:
                    return this.$t('exam.is_bool0')
            }
        },
        async get_info(){
            const information = await groupExamDetail(this.id)
            this.ruleForm = information
        }
    },
    created(){
        this.get_info()
    },
}
</script>
<style lang="scss" scoped>
.v-for-span + .v-for-span::before{
    content:'/'
}
    /deep/.el-form-item__content{
        word-wrap: break-word;
        font-weight: bold;
    }
</style>